.user-menu.locker-to-locker-delivery .requests-list {
  max-height: 326px;
  overflow: auto;
}

.user-menu.locker-to-locker-delivery .requests-list::-webkit-scrollbar-track {
  background: rgba(194, 194, 194, 0.23);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  background-clip: padding-box;
}

.user-menu.locker-to-locker-delivery .requests-list::-webkit-scrollbar-thumb {
  background: #fc5726;
  border-radius: 11px;
}
.user-menu.locker-to-locker-delivery .requests-list::-webkit-scrollbar {
  width: 13px;
}
